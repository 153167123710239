import axios from "axios";
import locations from "./locations";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : locations.baseURL,
});

api.defaults.timeout = 20000;

export default api;
