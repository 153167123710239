import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Dialog,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, NavLink } from "react-router-dom";
import "../../../global.css";

const CardDialog = ({
  branch,
  handleCloseCardDialog,
  openCardDialog,
  setCardBrand,
}) => {
  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);

  const selectCard = (brand) => {
    setCardBrand(brand);
    handleCloseCardDialog();
  };

  const cardBrands = branch.paymentMethods;

  return (
    <Dialog
      onClose={() => {
        handleCloseCardDialog();
        //setSelectedNeighborhood(null);
      }}
      open={openCardDialog}
    >
      <Box sx={{ padding: "25px", width: { md: "400px" } }}>
        {selectedNeighborhood === null ? (
          <div className="wrapperData" style={{ marginBottom: "50px" }}>
            <Box
              lg={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                lg={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Selecione o tipo de cartão
              </Typography>
            </Box>
            <Box style={{ marginTop: "25px" }}>
              {cardBrands.map((brand) => (
                <>
                  <Box
                    key={brand.id}
                    onClick={() => selectCard(brand)}
                    className="neighborhood_box"
                  >
                    <Box display="flex" justifyContent="flex-start">
                      <AddIcon
                        width={10}
                        color="#CCCCCC"
                        style={{ marginRight: "10px" }}
                      />{" "}
                      <Typography variant="h6">{brand.name}</Typography>
                    </Box>
                  </Box>
                </>
              ))}
            </Box>
          </div>
        ) : (
          <div className="wrapperData" style={{ marginBottom: "50px" }}></div>
        )}
      </Box>
    </Dialog>
  );
};
export default CardDialog;
