export const IngredientsBowl = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="-48.65 0 507.58 507.58"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <g data-name="Capa 2" id="Capa_2">
        <g id="Outline">
          <g id="SaladBowl">
            <path
              class="cls-1"
              d="M0,302.44C0,415.55,92,507.58,205.14,507.58A205,205,0,0,0,410.28,302.44a8.51,8.51,0,0,0-8.5-8.5H8.5A8.51,8.51,0,0,0,0,302.44Zm393.09,8.5a188.49,188.49,0,0,1-5.57,37.8H22.78a186.85,186.85,0,0,1-5.59-37.8Zm-368.4,44.8H385.6A188.14,188.14,0,0,1,205.14,490.58C119.9,490.58,47.73,433.6,24.69,355.74Z"
            />

            <path
              class="cls-1"
              d="M264.28,452.88a137.9,137.9,0,0,0,49.87-36.62,3.5,3.5,0,0,1,5.31,4.57A145.2,145.2,0,0,1,267,459.32a3.55,3.55,0,0,1-1.38.28,3.5,3.5,0,0,1-1.38-6.72Z"
            />

            <path
              class="cls-1"
              d="M378.42,110.16c16.45-17.37,12.24-43,12.05-44a8.5,8.5,0,0,0-13.91-5L339.24,93.17l11.31-56.08a8.5,8.5,0,0,0-11.42-9.6c-1.12.43-27.63,11-35,36.29-3.38,11.54-2.22,23.94,3.42,37a48.22,48.22,0,0,0-21.77,1.59c-12.27,4-21.67,13.24-27.93,27.43A114.6,114.6,0,0,0,253,143.45c0,.1-.06.21-.09.31-9.35,31.94-14.73,85.5-13.71,113.66a24.5,24.5,0,0,0,14.68,21.67,24.76,24.76,0,0,0,10,2.13,24.51,24.51,0,0,0,15.89-5.87c12.91-10.94,30.63-29.33,46.26-48,12.11-14.48,22.06-28,28.77-39.1h0a112.55,112.55,0,0,0,6.7-12.6c6.27-14.18,6.76-27.34,1.46-39.11a45.66,45.66,0,0,0-9.12-13.16C363.93,121.18,372.15,116.78,378.42,110.16Zm-56.62,13h0l.26.13.3.15.27.13.08,0,.19.07.34.13.18.07c.34.11,18,6.06,24.09,19.75,3.24,7.27,2.74,15.51-1.54,25.19a84.88,84.88,0,0,1-4.15,8l-14.15-7.55a3.5,3.5,0,1,0-3.3,6.17l13.81,7.37a332.63,332.63,0,0,1-24.34,32.62l-27.21-12.53a3.5,3.5,0,1,0-2.93,6.36L309.16,221c-14,16.37-29.18,31.94-40.36,41.41a7.66,7.66,0,0,1-12.62-5.57c-.93-25.59,3.67-73.13,11.81-103.84l27.15,12.19a3.45,3.45,0,0,0,1.43.31,3.5,3.5,0,0,0,1.44-6.69l-28.08-12.61a90.89,90.89,0,0,1,3.45-9.46c4.28-9.68,10-15.6,17.58-18.1C305.26,113.87,321.63,123,321.8,123.11Zm8.08-69.51-7.7,38.14c-3.18-8.42-3.79-16.14-1.79-23.09A34.48,34.48,0,0,1,329.88,53.6Zm42.54,33.48A27.91,27.91,0,0,1,366,98.55q-6,6.33-16.73,8.41Z"
            />

            <path
              class="cls-1"
              d="M153.92,151.61c-12.56-31-41.06-51.41-70.11-51.41H83c-.09-.24-.19-.49-.29-.73C77,85.5,59.76,79.26,44.21,85.56a32.19,32.19,0,0,0-16.66,14.92,25.87,25.87,0,0,0-1.27,21.85,24.85,24.85,0,0,0,5,7.79C18.79,150.63,17,178.29,27,203.05c12.57,31,40.68,51.37,69.88,51.42,4.54,7.47,13.07,11.8,22.29,11.8a30.25,30.25,0,0,0,11.4-2.25c14.28-5.78,21.65-21,16.43-33.84-.15-.35-.3-.7-.46-1.05C161.86,208.26,164.86,178.6,153.92,151.61Zm-24.25,71.32a8.48,8.48,0,0,0,.23,11.66,6.08,6.08,0,0,1,1.32,2c1.67,4.11-1.56,9.47-7,11.7s-11.55.63-13.22-3.49a7.07,7.07,0,0,1-.27-.95,8.49,8.49,0,0,0-9.15-6.59c-24,2.43-48.13-14.26-58.79-40.57-9-22.16-6.28-46.92,6.89-63.07a8.5,8.5,0,0,0-3-13.08,8.81,8.81,0,0,1-4.6-4.58,9.32,9.32,0,0,1,.63-7.67,15.17,15.17,0,0,1,7.94-7c6.74-2.74,14.22-.66,16.33,4.53a8.08,8.08,0,0,1,.55,3.22,8.49,8.49,0,0,0,9.76,8.62,44.08,44.08,0,0,1,6.68-.5c22.22,0,44.29,16.24,54.25,40.8C147.62,181.32,144.28,206.81,129.67,222.93Z"
            />

            <path
              class="cls-1"
              d="M119,211.83a3.41,3.41,0,0,1-1.54.36,3.5,3.5,0,0,1-1.53-6.64A19.93,19.93,0,0,0,125,178.9a3.5,3.5,0,0,1,6.29-3.07,26.91,26.91,0,0,1-12.37,36Z"
            />

            <path
              class="cls-1"
              d="M197.13,264.73a16.15,16.15,0,1,0,16.14-16.14A16.17,16.17,0,0,0,197.13,264.73Zm7,0a9.15,9.15,0,1,1,9.14,9.15A9.15,9.15,0,0,1,204.13,264.73Z"
            />

            <path
              class="cls-1"
              d="M131.88,59A18.86,18.86,0,1,0,113,77.86,18.88,18.88,0,0,0,131.88,59Zm-7,0A11.86,11.86,0,1,1,113,47.15,11.87,11.87,0,0,1,124.88,59Z"
            />

            <path
              class="cls-1"
              d="M404.31,215.06A20.34,20.34,0,1,0,384,235.39,20.36,20.36,0,0,0,404.31,215.06Zm-7,0A13.34,13.34,0,1,1,384,201.72,13.35,13.35,0,0,1,397.31,215.06Z"
            />

            <path
              class="cls-1"
              d="M25.46,257.78a11.33,11.33,0,1,0,11.33-11.32A11.34,11.34,0,0,0,25.46,257.78Zm7,0a4.33,4.33,0,1,1,4.33,4.33A4.33,4.33,0,0,1,32.46,257.78Z"
            />

            <path
              class="cls-1"
              d="M283.38,32.8a11.33,11.33,0,1,0-11.32,11.33A11.34,11.34,0,0,0,283.38,32.8Zm-7,0a4.33,4.33,0,1,1-4.32-4.33A4.33,4.33,0,0,1,276.38,32.8Z"
            />

            <path
              class="cls-1"
              d="M215.62,156.21C231,135.87,247.79,104.62,240,71.32,233.54,43.48,211.13,19.79,173.42.9a8.51,8.51,0,0,0-11.5,4c-11,23.4-20.7,46.53-18,72.18,3.06,29.38,22.28,56.38,58.76,82.55a8.38,8.38,0,0,0,2.27,1.15c-1.22,18.58-4.89,34.48-10.75,46.38a3.5,3.5,0,0,0,1.59,4.69,3.59,3.59,0,0,0,1.55.36,3.51,3.51,0,0,0,3.14-1.95c6.39-13,10.34-30.23,11.55-50.27a8.38,8.38,0,0,0,2.32-2.06c.42-.54.86-1.11,1.3-1.7Zm-3.74-24.92C210.31,107,205.15,83,197.49,65.55a3.5,3.5,0,1,0-6.41,2.81c8.26,18.84,13.49,45.66,14.22,72C177.71,119,163.13,97.68,160.8,75.3c-1.93-18.58,4.49-36.74,12.77-55.11,28.27,15.67,45.05,34.14,49.9,55C228.17,95.33,221.22,115.24,211.88,131.29Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
