const translateStatus = (input) => {
  const nums = {
    0: "Cancelado",
    1: "Aguardando aprovação",
    2: "Em preparo",
    3: "Pronto para retirada",
    4: "Saiu para entrega",
    5: "Entregue",
    6: "Concluído",
    7: "Reembolsado",
  };
  return nums[input];
};
const translateStatusColors = (input) => {
  const nums = {
    0: "#ff7474",
    1: "#eee5d1",
    2: "#d1eee5",
    3: "#d1d2ee",
    4: "#d1d2ee",
    5: "#c7ffb3",
    6: "#ccdaff",
    7: "#CCCCCC",
  };
  return nums[input];
};
export { translateStatus, translateStatusColors };
