const calculateCouponDiscount = (total, coupon) => {
  let totalDiscount = 0;

  /* console.log("total", total);
  console.log("coupon", coupon); */

  if (coupon.reward.type && coupon.reward.type === 0) {
    totalDiscount = total - coupon.reward.amount;
  }

  if (coupon.reward.type && coupon.reward.type === 1) {
    totalDiscount = total - total * (1 - coupon.reward.amount / 100);
  }

  console.log(totalDiscount);

  return totalDiscount;
};
export default calculateCouponDiscount;
