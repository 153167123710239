import React from "react";
import { Box, Typography, Dialog } from "@mui/material";
import CheckoutSpinner from "../Spinner/Spinner";

const SendingOrderDialog = ({ openSuccess, handleCloseSucess }) => {
  return (
    <Dialog onClose={handleCloseSucess} open={openSuccess} maxWidth={600}>
      <Box style={{ padding: "25px" }}>
        <div className="wrapperData" style={{ marginBottom: "50px" }}>
          <Box
            lg={{
              textAlign: "center",
            }}
          >
            <Box style={{ marginBottom: "25px" }}>
              <CheckoutSpinner />
            </Box>
            <Typography
              variant="h2"
              lg={{
                width: "100%",
                textAlign: "center",
              }}
            >
              Enviando seu pedido
            </Typography>
            <Typography
              variant="h6"
              lg={{
                width: "100%",
                textAlign: "center",
              }}
            >
              Por favor, aguarde...
            </Typography>
          </Box>
        </div>
      </Box>
    </Dialog>
  );
};
export default SendingOrderDialog;
