import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Radio, Divider } from "@mui/material";
import { useApp } from "../../../context/appContext";

const PaymentMethods = ({
  branch,
  selectedValue,
  handlePaymentChange,
  selectedIfoodValue,
  handleIfoodPaymentChange,
  translatePayment,
}) => {
  const { state } = useApp();

  const defaultPaymentChecked = (name) => {
    const validPayment = branch.paymentMethodsDefault.filter(
      (p) => p.name === name && p.active
    );

    if (validPayment.length) {
      return true;
    }
  };

  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "25px" }}>
      <>
        <Box
          style={{
            backgroundColor: "#f5f5f5",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
            }}
          >
            Selecione a forma de pagamento
          </Typography>
        </Box>
        <Box
          style={{
            marginBottom: "15px",
            border: "1px solid #f5f5f5",
            padding: "15px",
          }}
        >
          {state.consumeType &&
          state.consumeType === 1 &&
          state.deliveryInfo &&
          state.deliveryInfo.type &&
          state.deliveryInfo.type === "ifood" &&
          state.deliveryInfo.quote ? (
            <>
              {state.deliveryInfo.quote.paymentMethods.map((payment) => (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={10} lg={10}>
                      <Typography variant="h6">
                        {payment.brand
                          ? `${payment.brand} - ${translatePayment(
                              payment.method
                            )}`
                          : `${translatePayment(payment.method)}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                      <Box display="flex" justifyContent="flex-end">
                        <Radio
                          checked={selectedIfoodValue === payment.id}
                          onChange={handleIfoodPaymentChange}
                          value={payment.id}
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  />
                </>
              ))}
            </>
          ) : (
            <>
              {defaultPaymentChecked("cash") && (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={10} lg={10}>
                      <Typography variant="h6">Dinheiro</Typography>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                      <Box display="flex" justifyContent="flex-end">
                        <Radio
                          checked={selectedValue === "cash"}
                          onChange={handlePaymentChange}
                          value="cash"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  />
                </>
              )}
              {defaultPaymentChecked("pix") && (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={10} lg={10}>
                      <Typography variant="h6">Pix</Typography>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                      <Box display="flex" justifyContent="flex-end">
                        <Radio
                          checked={selectedValue === "pix"}
                          onChange={handlePaymentChange}
                          value="pix"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  />
                </>
              )}
              {defaultPaymentChecked("card") && (
                <>
                  <Grid container spacing={0}>
                    <Grid item xs={10} lg={10}>
                      <Typography variant="h6">Cartão na Entrega</Typography>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                      <Box display="flex" justifyContent="flex-end">
                        <Radio
                          checked={selectedValue === "card"}
                          onChange={handlePaymentChange}
                          value="card"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "B" }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Box>
      </>
    </Grid>
  );
};
export default PaymentMethods;
