import React from "react";
import { Grid, Box, Typography, Button, Stack, Chip } from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import calculateTotalPrice from "../../../utils/calculateTotalCart";
import currency from "currency-formatter";
import { useApp } from "../../../context/appContext";
import calculateCouponDiscount from "../../../utils/calculateCouponDiscount";

const CheckoutFooter = ({ cart, deliveryFee }) => {
  //console.log("cart in footer", cart);

  const { state } = useApp();

  const cartSubtotal = calculateTotalPrice(cart);

  ///CONSIDERAR SE O CUPOM É FREE DELIVERY
  const total = state.appliedCoupon
    ? cartSubtotal +
      deliveryFee -
      calculateCouponDiscount(cartSubtotal, state.appliedCoupon)
    : cartSubtotal + deliveryFee;

  return (
    <Grid
      item
      xs={12}
      lg={12}
      style={{ marginTop: "50px", marginBottom: "50px" }}
    >
      <Box align="right">
        <Typography variant="h6">
          <span style={{ fontSize: "12px" }}>Subtotal:</span>{" "}
          {currency.format(cartSubtotal, {
            code: "BRL",
          })}
        </Typography>
        {/* COUPON DETAILS */}
        {state.appliedCoupon && (
          <Box
            sx={{
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            <Typography variant="h6">
              <span style={{ fontSize: "12px" }}>Cupom aplicado:</span>{" "}
            </Typography>
            <Chip
              label={state.appliedCoupon.title}
              sx={{
                background: "#39cb7f",
                color: "#FFFFFF",
                textTransform: "uppercase",
                letterSpacing: "1px",
                fontSize: "12px",
                fontWeight: "600",
                borderRadius: "5px",
              }}
            />
            {/* <Typography variant="h6">{state.appliedCoupon.title}</Typography> */}
          </Box>
        )}
        {state.appliedCoupon && state.appliedCoupon.reward.type !== 2 && (
          <Typography variant="h6">
            <span style={{ fontSize: "12px" }}>Total do desconto:</span>{" "}
            {currency.format(
              calculateCouponDiscount(cartSubtotal, state.appliedCoupon),
              {
                code: "BRL",
              }
            )}
          </Typography>
        )}
        <Typography variant="h6">
          <span style={{ fontSize: "12px" }}>Taxa de entrega:</span>{" "}
          {currency.format(deliveryFee, {
            code: "BRL",
          })}
        </Typography>
        <Typography variant="h6">
          <span style={{ fontSize: "12px" }}>Total:</span>{" "}
          {currency.format(total, {
            code: "BRL",
          })}
        </Typography>
      </Box>
    </Grid>
  );
};
export default CheckoutFooter;
