import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Dialog,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, NavLink } from "react-router-dom";
import "../../../global.css";

const PixDialog = ({
  branch,
  handleClosePixDialog,
  openPixDialog,
  setCashChange,
}) => {
  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);

  const pixPaymentData = branch.paymentMethodsDefault.filter(
    (p) => p.name === "pix"
  )[0];

  const handleChange = (e) => {
    setCashChange(e);
  };

  return (
    <Dialog
      onClose={() => {
        handleClosePixDialog();
      }}
      open={openPixDialog}
    >
      <Box sx={{ padding: "25px", width: { md: "400px" } }}>
        <div className="wrapperData">
          <Box
            lg={{
              textAlign: "center",
            }}
          >
            <Typography
              variant="h2"
              lg={{
                width: "100%",
                textAlign: "center",
              }}
            >
              Pagar com o Pix
            </Typography>
            <Typography
              variant="h6"
              lg={{
                width: "100%",
                textAlign: "center",
                marginBottom: "25px",
              }}
            >
              Para sua segurança, esses são os nossos dados Pix
            </Typography>
          </Box>
          <Box style={{ marginTop: "25px" }}>
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                lg={6}
                sx={{
                  marginBottom: "0",
                }}
              >
                <Typography variant="h5">
                  <strong>Chave Pix:</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                lg={6}
                sx={{
                  marginBottom: "0",
                  textAlign: "right",
                }}
              >
                <Typography variant="h5">{pixPaymentData.pixKey}</Typography>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                lg={6}
                sx={{
                  marginBottom: "0",
                }}
              >
                <Typography variant="h5">
                  <strong>Recebedor:</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                lg={6}
                sx={{
                  marginBottom: "0",
                  textAlign: "right",
                }}
              >
                <Typography variant="h5">
                  {pixPaymentData.pixReceiverName}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                lg={6}
                sx={{
                  marginBottom: "0",
                }}
              >
                <Typography variant="h5">
                  <strong>Tipo de Chave:</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                lg={6}
                sx={{
                  marginBottom: "0",
                  textAlign: "right",
                }}
              >
                <Typography variant="h5">
                  {pixPaymentData.pixKeyType}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ width: "100%", marginTop: "25px" }}>
            <Button
              onClick={() => handleClosePixDialog()}
              fullWidth
              variant="contained"
            >
              Ok, entendi!
            </Button>
          </Box>
        </div>
      </Box>
    </Dialog>
  );
};
export default PixDialog;
