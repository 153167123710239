import React from "react";
import { Grid, Box, Typography, Button, TextField } from "@mui/material";
import { Link, NavLink, useParams } from "react-router-dom";
import { useApp } from "../../context/appContext";
import OrderViewHeader from "./OrderViewHeader";
import OrderItemsView from "./OrderItems";
import OrderViewFooter from "./OrderFooter";

const OrderView = () => {
  // 2
  const { state } = useApp();

  const { restaurantName } = useParams();

  const testState = () => {
    console.log(state);
  };

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12} style={{ marginTop: "50px" }}>
          <Typography variant="h3">
            Pedido Nº #{state.lastOrder.seq.toString().padStart(4, "0")}{" "}
            confirmado
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} style={{ marginTop: "50px" }}>
          <Box>
            <Typography>
              <strong>
                Obrigado por sua compra. Confira o resumo do seu pedido.
              </strong>
            </Typography>
          </Box>
        </Grid>

        <OrderViewHeader order={state.lastOrder} />
        <OrderItemsView order={state.lastOrder} />
        <OrderViewFooter order={state.lastOrder} />

        <Grid item xs={12} lg={12} style={{ marginTop: "50px" }}>
          <Link to={`/${restaurantName}/menu`}>
            <Button variant="contained">Voltar ao menu</Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderView;
