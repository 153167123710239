import React from "react";
import { useEffect, useState } from "react";
import { CardMedia, Box, Skeleton, Typography } from "@mui/material";
import locations from "../../../services/locations";
import { IngredientsBowl } from "../../../assets/svgIcons/icons";
import { CircularProgress } from "@mui/material";
import currency from "currency-formatter";

const ProductPrice = ({ product }) => {
  const calculateLowestSum = () => {
    let totalSum = 0;

    //calculates the lowest product price based on mandatory complement groups and
    //their minimum required quantities

    product.complementGroups
      .filter((c) => c.mandatory)
      .forEach((group) => {
        //console.log("group", group);
        const sortedPrices = group.complements
          .map((item) => item.price * group.rules.min)
          .sort((a, b) => a - b);
        const lowestSum = sortedPrices
          .slice(0, 1)
          .reduce((acc, curr) => acc + curr, 0); // Considering minQuantity as 1
        totalSum += lowestSum;
      });

    //console.log(totalSum);

    return totalSum;
  };

  useEffect(() => {
    if (product.complementGroups.length) {
      calculateLowestSum();
    }
  }, []);

  return product.complementGroups.filter((c) => c.mandatory).length > 0 ? (
    <Typography
      sx={{
        fontWeight: "800",
        color: "secondary.main",
      }}
    >
      À partir de{" "}
      {currency.format(
        product.offerPrice
          ? product.offerPrice + calculateLowestSum()
          : product.price + calculateLowestSum(),
        {
          code: "BRL",
        }
      )}
    </Typography>
  ) : product.isOffer ? (
    <>
      <Typography
        sx={{
          color: "#a4a4a4",
          fontSize: "12px",
        }}
      >
        De{" "}
        <span
          sx={{
            textDecoration: "line-through",
          }}
        >
          {currency.format(product.price, {
            code: "BRL",
          })}{" "}
        </span>
        por:
      </Typography>
      <Typography
        sx={{
          fontWeight: "800",
          color: "secondary.main",
        }}
      >
        {currency.format(
          product.offerPrice ? product.offerPrice : product.price,
          {
            code: "BRL",
          }
        )}
      </Typography>
    </>
  ) : (
    <Typography
      sx={{
        fontWeight: "800",
        color: "secondary.main",
      }}
    >
      {currency.format(product.price, {
        code: "BRL",
      })}
    </Typography>
  );
};

export default ProductPrice;
