import React from "react";
import { Grid, Box, Typography, Button, Stack, Chip } from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import calculateTotalPrice from "../../utils/calculateTotalCart";
import currency from "currency-formatter";

const OrderViewFooter = ({ order }) => {
  const cartSubtotal = calculateTotalPrice(order.items);
  const total = cartSubtotal + order.deliveryFee;

  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "50px" }}>
      <Box align="right">
        <Typography variant="h6">
          <span style={{ fontSize: "12px" }}>Subtotal:</span>{" "}
          {currency.format(cartSubtotal, {
            code: "BRL",
          })}
        </Typography>
        <Typography variant="h6">
          <span style={{ fontSize: "12px" }}>Taxa de entrega:</span>{" "}
          {currency.format(order.deliveryFee, {
            code: "BRL",
          })}
        </Typography>
        <Typography variant="h6">
          <span style={{ fontSize: "12px" }}>Total:</span>{" "}
          {currency.format(total, {
            code: "BRL",
          })}
        </Typography>
      </Box>
    </Grid>
  );
};
export default OrderViewFooter;
