const findAverage = (arr) => {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
  }
  return sum / arr.length;
};

const calculatePreparationTime = (cart, deliveryTime) => {
  let totalTime = cart.map((item) => {
    return item.preparationTime;
  });

  const averageTime = Math.round(findAverage(totalTime) + deliveryTime);

  /* console.log("totalTime", totalTime);
  console.log("average time", findAverage(totalTime));
  console.log("average time + area deliveryTime", averageTime); */

  return averageTime;
};
export default calculatePreparationTime;
