import React, { useState, useEffect } from "react";
import {
  Alert,
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import currency from "currency-formatter";
import { useApp } from "../../context/appContext";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { RemoveCircle } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";

const TaxPayerInfo = ({ taxPayerId, setTaxPayerId }) => {
  const { state, applyCoupon } = useApp();

  const [couponCode, setCouponCode] = useState("");

  const checkCoupon = () => {
    const coupons = state.coupons.filter(
      (c) => c.rules.couponCode.toLowerCase() === couponCode.toLowerCase()
    );

    //FAZER A CONSULTA SE O CUPOM É ENTREGA AQUI

    if (coupons.length !== 0) {
      console.log("cupom válido", couponCode);
      applyCoupon(coupons[0]);
    } else {
      console.log("cupom inválido", couponCode);
      applyCoupon(null);
    }
  };

  const removeCoupon = () => {
    applyCoupon(null);
    setCouponCode("");
  };

  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "25px" }}>
      <>
        <Box
          style={{
            backgroundColor: "#ecf7ff",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
            }}
          >
            Deseja colocar o CPF na nota?
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Grid container>
            <Grid item sm={12} md={12}>
              <TextField
                onChange={(e) => setTaxPayerId(e.target.value)}
                autoComplete="off"
                style={{ marginTop: "25px" }}
                id="default-textarea"
                label="Digite seu CPF"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {/* <Box
          style={{
            marginBottom: "15px",
            border: "1px solid #ecf7ff",
            padding: "15px",
          }}
        ></Box> */}
      </>
    </Grid>
  );
};
export default TaxPayerInfo;
