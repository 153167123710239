import React from "react";
import { Grid, Box, Typography, Button, Stack, Chip } from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import calculatePreparationTime from "../../../utils/calculatePreparationTime";
import { useApp } from "../../../context/appContext";

const OrderHeader = ({
  currentCustomer,
  handleOpenEditCustomerDialog,
  cart,
  deliveryFeeTime,
}) => {
  const { state } = useApp();
  const deliveryTime = calculatePreparationTime(cart, deliveryFeeTime);

  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "50px" }}>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Box align="left">
          <Typography variant="h6" style={{ marginBottom: "15px" }}>
            Este pedido deverá ser entregue a:
          </Typography>
          <Typography variant="h3">{currentCustomer.name}</Typography>
          <Typography variant="h6">{currentCustomer.phone}</Typography>
          {state.consumeType === 1 && (
            <Chip
              icon={<WatchLaterIcon color="#FFFFFF" />}
              sx={{
                pl: "4px",
                pr: "4px",
                backgroundColor: "#CCC",
                color: "#fff",
                marginTop: "15px",
              }}
              size="small"
              label={`Tempo aproximado para entrega: ${
                state.deliveryInfo && state.deliveryInfo.deliveryTime
              }min`}
            ></Chip>
          )}
        </Box>
        <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
          <Box>
            <Button
              onClick={() => handleOpenEditCustomerDialog()}
              variant="outlined"
              color="info"
              sx={{
                mr: 1,
                mb: 1,
              }}
            >
              Alterar
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Grid>
  );
};
export default OrderHeader;
