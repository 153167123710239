const reducer = (state, action) => {
  switch (action.type) {
    case "SET_COMPANY":
      return { ...state, company: action.payload };
    case "SET_CONSUMETYPE":
      return { ...state, consumeType: action.payload };
    case "SET_ADDRESS":
      return { ...state, address: action.payload };
    case "SET_BRANCHES":
      return { ...state, branches: action.payload };
    case "SET_BRANCH":
      return { ...state, branch: action.payload };
    case "SET_DELIVERYINFO":
      return { ...state, deliveryInfo: action.payload };
    case "SET_CLOSED":
      return { ...state, closed: action.payload };
    case "SET_OPEN":
      return { ...state, isOpen: action.payload };
    case "SET_USER":
      return { ...state, user: action.payload };
    case "SET_CUSTOMER":
      return { ...state, customer: action.payload };
    case "SET_CATEGORIES":
      return { ...state, categories: action.payload };
    case "SET_PRODUCTS":
      return { ...state, products: action.payload };
    case "SET_COMPLEMENTGROUPS":
      return { ...state, complementGroups: action.payload };
    case "SET_COMPLEMENTS":
      return { ...state, complements: action.payload };
    case "SET_OFFERS":
      return { ...state, offers: action.payload };
    case "SET_COUPONS":
      return { ...state, coupons: action.payload };
    case "SET_APPLIEDCOUPON":
      return { ...state, appliedCoupon: action.payload };
    case "SET_CART":
      return { ...state, cart: action.payload };
    case "SET_ORDERS":
      return { ...state, orders: action.payload };
    case "SET_LASTORDER":
      return { ...state, lastOrder: action.payload };
    case "SET_UUID":
      return { ...state, uuid: action.payload };
    case "SET_LOADED":
      return { ...state, loaded: action.payload };
    default:
      return state;
  }
};

export { reducer };
