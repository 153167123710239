import { lazy } from "react";
import { useState } from "react";
import { Navigate, Outlet, useOutletContext, Route } from "react-router-dom";
import ShoppingCart from "../views/carrinho/Carrinho.js";
import CheckOut from "../views/checkout/Checkout.js";
import Success from "../views/OrderView/OrderView.js";
import Dashboard1 from "../views/dashboards/Dashboard1.js";
import RecoverCartView from "../views/carrinho/RecoverCart.js";
import SingleReview from "../views/OrderView/SingleReview.js";
import OrderReviewPage from "../views/OrderView/OrderReviewPage.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/

const ContentLayout = lazy(() =>
  import("../layouts/FullLayout/ContentLayout.js")
);

/*****Pages******/
//const Dashboard1 = lazy(() => import("../views/dashboards/Dashboard1.js"));

/*****Tables******/
const BasicTable = lazy(() => import("../views/tables/BasicTable.js"));

// form elements
const ExAutoComplete = lazy(() =>
  import("../views/FormElements/ExAutoComplete.js")
);
const ExButton = lazy(() => import("../views/FormElements/ExButton.js"));
const ExCheckbox = lazy(() => import("../views/FormElements/ExCheckbox.js"));
const ExRadio = lazy(() => import("../views/FormElements/ExRadio.js"));
const ExSlider = lazy(() => import("../views/FormElements/ExSlider.js"));
const ExSwitch = lazy(() => import("../views/FormElements/ExSwitch.js"));
// form layouts
const FormLayouts = lazy(() => import("../views/FormLayouts/FormLayouts.js"));

// orders
const OrdersPanel = lazy(() => import("../views/orders/OrdersPanel.js"));
// orders

//menu
const ShopMenu = lazy(() => import("../views/shopmenu/ShopMenu.js"));
//menu

//clients
const ClientsPanel = lazy(() => import("../views/clients/ClientsPanel.js"));
//clients

//product demo view
const ProductView = lazy(() => import("../views/product/ProductView.js"));
//product demo view

//shopping cart
//const ShoppingCart = lazy(() => import("../views/carrinho/Carrinho.js"));
//shopping cart

//signin
const SignIn = lazy(() => import("../views/SignIn/SignIn.js"));
//signin

//checkout
//const Checkout = lazy(() => import("../views/checkout/Checkout.js"));
//checkout

//success
//const Success = lazy(() => import("../views/OrderView/OrderView.js"));
//success

//consume type
const ConsumeType = lazy(() => import("../views/consumeType/consumeType.js"));
//consume type

//order single view
const OrderSingleView = lazy(() =>
  import("../views/OrderView/OrderSingleView.js")
);
//order single view

/*****Routes******/

const DefaultHome = () => {
  return <div>Bem-Vindo ao Cardápio WeDo!</div>;
};

const RestaurantLayout = (props) => {
  const outletContext = useOutletContext();

  return (
    <>
      <Outlet {...props} />
      {/* Other components for the restaurant layout */}
    </>
  );
};

const ThemeRoutes = [
  {
    path: ":restaurantName",
    children: [
      {
        path: "selecionar-consumo",
        element: <ConsumeType />,
      },
    ],
  },
  {
    path: "/",
    element: <FullLayout />,
    children: [
      {
        path: ":restaurantName",
        children: [
          { path: "", element: <Navigate to="selecionar-consumo" /> },
          { path: "menu", exact: true, element: <Dashboard1 /> },
          { path: "pedidos", exact: true, element: <OrdersPanel /> },
          { path: "clientes", exact: true, element: <ClientsPanel /> },
          {
            path: "continuar/:cartId",
            exact: true,
            element: <RecoverCartView />,
          },
          {
            path: "avaliar/:reviewId",
            exact: true,
            element: <OrderReviewPage />,
          },
          { path: "produto/:productId", exact: true, element: <ProductView /> },
          {
            path: "pedido/:orderId",
            exact: true,
            element: <OrderSingleView />,
          },
          { path: "carrinho", exact: true, element: <ShoppingCart /> },
          { path: "identificacao", exact: true, element: <SignIn /> },
          { path: "finalizar", exact: true, element: <CheckOut /> },
          { path: "sucesso", exact: true, element: <Success /> },
        ],
      },
      {
        path: "*",
        element: <DefaultHome />, // Render custom component when :restaurantName is not provided
      },
    ],
  },
];

export default ThemeRoutes;
