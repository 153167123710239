import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import currency from "currency-formatter";

const OrderItemsView = ({ order }) => {
  return order.items.map((item) => (
    <Grid id={item.cart_id} xs={12} lg={12} style={{ marginTop: "25px" }}>
      <>
        <Box
          style={{
            backgroundColor: "#f5f5f5",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={10} lg={10}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                }}
              >
                {item.quantity}x {item.name}
              </Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent="flex-end"
              item
              xs={2}
              lg={2}
            ></Grid>
          </Grid>
        </Box>
        {item.complements.length > 0 && (
          <Box
            style={{
              marginBottom: "15px",
              border: "1px solid #f5f5f5",
              padding: "15px",
            }}
          >
            {item.complements.map((complement) => (
              <>
                <Grid container spacing={0}>
                  <Grid item xs={10} lg={10}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                        color: "#bbbbbb",
                      }}
                    >
                      {complement.name}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    justifyContent="flex-end"
                    item
                    xs={2}
                    lg={2}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                        color: "#bbbbbb",
                      }}
                    >
                      {complement.mandatory ? "Obrigatório" : ""}
                    </Typography>
                  </Grid>
                </Grid>
                {complement.items.map((item) => (
                  <Grid container spacing={0}>
                    <Grid item xs={10} lg={10}>
                      <Typography variant="h6">
                        {item.quantity}x - {item.name}
                      </Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="flex-end"
                      item
                      xs={2}
                      lg={2}
                    >
                      <Typography variant="h6">
                        {currency.format(item.price * item.quantity, {
                          code: "BRL",
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
                <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
              </>
            ))}
            {item.notes !== "" && (
              <Grid item xs={12} lg={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "600",
                    color: "#eb9696",
                  }}
                >
                  Observações
                </Typography>
                <Typography variant="h6">{item.notes}</Typography>
              </Grid>
            )}
          </Box>
        )}
      </>
    </Grid>
  ));
};
export default OrderItemsView;
