import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";

function Navbar({ observerRefs, categories }) {
  const [visibleKey, setVisibleKey] = useState(0);
  const observers = useRef([]);

  const menuRefs = useRef([]);

  const scrollRef = useRef(null);

  // Set up refs for menu items
  /*   useEffect(() => {
    menuRefs.current = Array.from(Array(10).keys()).map(() =>
      React.createRef()
    );
  }, []); */

  const onClick = (item, key) => {
    setVisibleKey(key);

    if (observerRefs.current[key] && observerRefs.current[key]) {
      //observerRefs.current[key].scrollIntoView({ behavior: "smooth" });

      const element = observerRefs.current[key];
      const topOffset =
        element.getBoundingClientRect().top + window.pageYOffset; // Calculate the top position of the element
      const additionalOffset = 150; // Additional offset in pixels

      window.scrollTo({
        top: topOffset - additionalOffset,
        behavior: "smooth",
      });
    }
  };

  const observerCallback = async (e, key) => {
    if (e.length && e[0].isIntersecting) {
      setVisibleKey(key);

      /* menuRefs.current[key].scrollIntoView({
        behavior: "smooth",
      }); */

      scrollRef.current.scrollTo({
        top: 0,
        left: menuRefs.current[key].offsetLeft,
      });
      //scrollRef.current.scrollTo(menuRefs.current[key]);
    }
  };

  useEffect(() => {
    if (observerRefs.current?.length && observers.current) {
      Array.from(Array(10).keys()).forEach((_u, key) => {
        observers.current[key] = new IntersectionObserver((e) =>
          observerCallback(e, key)
        );
        if (observerRefs.current[key]) {
          observers.current[key].observe(observerRefs.current[key]);
        }
      });
    }
    return () =>
      observers.current?.forEach((observer) => observer?.current?.disconnect());
  }, [observerRefs, observers]);

  return (
    <>
      <div className="wrapperNavigation">
        <div className="navigation" ref={scrollRef}>
          <ul>
            {categories.map((item, key) => {
              return (
                <li
                  ref={(el) => (menuRefs.current[key] = el)}
                  name={item.category.toLowerCase()}
                  key={`item-${key}`}
                  className={`list${key === visibleKey ? " active" : ""}`}
                  onClick={() => onClick(item, key)}
                >
                  <a>
                    <span className="text">{item.category}</span>
                  </a>
                </li>
              );
            })}
            <div className="indicator"></div>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
