import React from "react";
import { useEffect, useState } from "react";
import { CardMedia, Box, Skeleton } from "@mui/material";
import locations from "../../../services/locations";
import { IngredientsBowl } from "../../../assets/svgIcons/icons";
import { CircularProgress } from "@mui/material";

const ImageCard = ({ product }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    //console.log("imageLoaded");
    setImageLoaded(true);
  };

  return product.image ? (
    <>
      {!imageLoaded && (
        <Skeleton
          variant="rectangular"
          width={151}
          height={100}
          sx={{
            borderRadius: "5px",
            marginRight: "10px",
            marginBottom: "12px",
          }}
        />
      )}
      <CardMedia
        component="img"
        onLoad={() => handleImageLoaded()}
        sx={
          !imageLoaded
            ? {
                display: "none",
              }
            : {
                display: "block",
                width: 151,
                height: 100,
                objectFit: "cover",
                borderRadius: "5px",
                marginRight: "10px",
                marginBottom: "12px",
              }
        }
        image={`${locations.baseURL}uploads/${product.image}`}
        alt={product.title}
      />
    </>
  ) : (
    <IngredientsBowl
      size={100}
      color="#CCC"
      xs={{
        marginRight: "10px",
        marginBottom: "12px",
      }}
    />
  );
};
export default ImageCard;
