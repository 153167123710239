function calculateHaversineDistance(lat1, lon1, lat2, lon2) {
  const radius = 6371; // Earth's radius in kilometers

  // Convert latitude and longitude to radians
  const lat1Rad = toRadians(lat1);
  const lon1Rad = toRadians(lon1);
  const lat2Rad = toRadians(lat2);
  const lon2Rad = toRadians(lon2);

  // Calculate the differences between coordinates
  const latDiff = lat2Rad - lat1Rad;
  const lonDiff = lon2Rad - lon1Rad;

  // Calculate the haversine of the differences
  const haversineLat = Math.sin(latDiff / 2) ** 2;
  const haversineLon = Math.sin(lonDiff / 2) ** 2;

  // Calculate the central angle using the haversine values
  const centralAngle =
    2 *
    Math.asin(
      Math.sqrt(
        haversineLat + Math.cos(lat1Rad) * Math.cos(lat2Rad) * haversineLon
      )
    );

  // Calculate the distance using the central angle and Earth's radius
  const distance = radius * centralAngle;

  return distance;
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadius = 6371; // Earth's radius in kilometers

  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;
  return distance;
}

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function isPointInsidePolygon(point, polygon) {
  // Extract the coordinates of the point
  const [pointX, pointY] = point;

  // Extract the coordinates of the polygon's exterior ring
  const exteriorRing = polygon.coordinates[0];

  let isInside = false;
  for (
    let i = 0, j = exteriorRing.length - 1;
    i < exteriorRing.length;
    j = i++
  ) {
    const [vertexX1, vertexY1] = exteriorRing[i];
    const [vertexX2, vertexY2] = exteriorRing[j];

    // Check if the point is within the bounds of the current edge
    const isOnEdge =
      vertexY1 > pointY !== vertexY2 > pointY &&
      pointX <
        ((vertexX2 - vertexX1) * (pointY - vertexY1)) / (vertexY2 - vertexY1) +
          vertexX1;

    if (isOnEdge) {
      return true; // Point is exactly on the edge of the polygon
    }

    // Toggle the isInside flag if the ray intersects with an edge
    if (
      vertexY1 > pointY !== vertexY2 > pointY &&
      pointX <
        ((vertexX2 - vertexX1) * (pointY - vertexY1)) / (vertexY2 - vertexY1) +
          vertexX1
    ) {
      isInside = !isInside;
    }
  }

  return isInside;
}

export { calculateHaversineDistance, isPointInsidePolygon, calculateDistance };
