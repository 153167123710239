import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

const OrderDeliveryAddress = ({ order }) => {
  return (
    <Grid xs={12} lg={12} style={{ marginTop: "25px" }}>
      <>
        <Box
          style={{
            backgroundColor: "#1a97f51f",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={10} lg={10}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                }}
              >
                Endereço para entrega e observações
              </Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent="flex-end"
              item
              xs={2}
              lg={2}
            ></Grid>
          </Grid>
        </Box>
        <Box
          style={{
            marginBottom: "15px",
            border: "1px solid #f5f5f5",
            padding: "15px",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={10} lg={10}>
              <Typography
                variant="h6"
                sx={{
                  color: "#000000",
                }}
              >
                {order.address.street}, {order.address.number}
                <br />
                {order.address.complement && order.address.complement !== ""
                  ? `${order.address.complement} - `
                  : ""}
                {order.address.neighborhood} - {order.address.reference}
              </Typography>
            </Grid>
            <Grid display="flex" justifyContent="flex-end" item xs={2} lg={2}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  color: "#000000",
                }}
              ></Typography>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
          {order.notes !== "" && (
            <Grid item xs={12} lg={12}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  color: "#eb9696",
                }}
              >
                Observações sobre a entrega
              </Typography>
              <Typography variant="h6">{order.notes}</Typography>
            </Grid>
          )}
        </Box>
      </>
    </Grid>
  );
};
export default OrderDeliveryAddress;
