import React from "react";
import { Grid, Box, Typography, Button, Stack } from "@mui/material";
import currency from "currency-formatter";

const DeliveryInfoOverview = ({
  deliveryFee,
  handleOpenUserAddressesDialog,
  selectedAddress,
}) => {
  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "25px" }}>
      <>
        <Box
          style={{
            backgroundColor: "#f5f5f5",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
            }}
          >
            Entrega
          </Typography>
        </Box>
        <Box
          style={{
            marginBottom: "15px",
            border: "1px solid #f5f5f5",
            padding: "15px",
          }}
        >
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Box align="left">
              {selectedAddress !== null ? (
                <>
                  <Typography variant="h6">
                    {`${selectedAddress.street} ${selectedAddress.number}`}{" "}
                    {`${selectedAddress.complement || ""}`}
                  </Typography>
                  <Typography variant="h6">
                    {`${selectedAddress.neighborhood}`}
                  </Typography>
                  <Typography variant="h6">
                  <strong>Taxa de entrega:</strong> {currency.format(deliveryFee, {
                  code: "BRL",
                  })}
                  </Typography>
                </>
              ) : (
                <Typography variant="h6">Selecione um endereço</Typography>
              )}
            </Box>
            <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
              <Box>
                <Button
                  onClick={() => handleOpenUserAddressesDialog()}
                  variant="outlined"
                  color="info"
                  sx={{
                    mr: 1,
                    mb: 1,
                  }}
                >
                  Trocar Endereço
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </>
    </Grid>
  );
};
export default DeliveryInfoOverview;
